import { combineReducers } from 'redux';
import persistedUserInterface from './persistedUserInterface/persistedUserInterface.duck';
import user from './user/user.duck';
import userInterface from './userInterface/userInterface.duck';
import travelAndExpenses from './tasks/travelAndExpenses/travelAndExpenses.duck';
import purchaseRequests from './tasks/purchaseRequests/purchaseRequests.duck';
import commonTasks from './tasks/tasks.duck';

export default combineReducers({
  user,
  tasks: combineReducers({
    purchaseRequests,
    travelAndExpenses,
    common: commonTasks,
  }),
  userInterface,
  persistedUserInterface,
});
