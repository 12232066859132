import { IS_CORDOVA } from 'utils';
import { useTotalPendingCount } from './tasks';

export const useAppIconBadge = () => {
  const totalPendingCount = useTotalPendingCount();
  if (
    IS_CORDOVA() &&
    Number.isInteger(totalPendingCount) &&
    window.cordova?.plugins?.notification
  ) {
    window.cordova.plugins.notification.badge.get(badge => {
      if (badge !== totalPendingCount) {
        localStorage.setItem('totalPendingCount', totalPendingCount);
        window.cordova.plugins.notification.badge.set(totalPendingCount);
      }
    });
  }
};
