/* eslint-disable no-shadow */
import React from 'react';
import styled, { css } from 'styled-components';
import borealisTheme from 'theme/theme';

const StyledPillWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledPillLabel = styled.div`
  display: inline;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 12px;
  ${({ outlined }) =>
    outlined
      ? css`
          line-height: 18px;
          border: 1px solid ${({ color }) => color};
          color: ${({ color }) => color};
          padding: 2px ${({ theme }) => theme.space[2]}px;
          @media (prefers-color-scheme: dark) {
            border: 1px solid ${({ darkModeColor }) => darkModeColor};
            color: ${({ darkModeColor }) => darkModeColor};
          }
        `
      : css`
          background-color: ${({ color }) => color};
          color: white;
          padding: ${({ theme }) => theme.space[1]}px 14px;
          @media (prefers-color-scheme: dark) {
            background-color: ${({ darkModeColor }) => darkModeColor};
            color: ${({ theme }) => theme.colors.black};
          }
        `}
`;

const StyledPillValue = styled.div`
  color: ${({ color }) => color};
  margin-left: ${({ theme }) => theme.space[2]}px;
  letter-spacing: 0.55px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  @media (prefers-color-scheme: dark) {
    color: ${({ theme }) => theme.colors.darkerWhite};
  }
`;

export const Pill = ({
  color = borealisTheme.colors.primary,
  darkModeColor = borealisTheme.colors.tertiary,
  outlined,
  label,
  value,
  className,
  ...props
}) => (
  <StyledPillWrapper className={className}>
    <StyledPillLabel
      color={color}
      darkModeColor={darkModeColor}
      outlined={outlined}
    >
      {label}
    </StyledPillLabel>
    <StyledPillValue darkModeColor={darkModeColor} color={color} {...props}>
      {value}
    </StyledPillValue>
  </StyledPillWrapper>
);
