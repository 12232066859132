/* eslint-disable import/no-cycle */
/* eslint-disable no-alert */
import jwtDecode from 'jwt-decode';
import { endpoints, routes } from 'config';
import { debounce } from './async';
import { isLocal } from './environment';
import { IS_CORDOVA } from './responsive';
import { logSentryError } from './secureFetch';

export const isOnCordovaLogoutPage = () => {
  return window.location.href.includes(routes.logoutCordova);
};

export const insecurlyVerifyJwtToken = token => {
  try {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.nbf && decodedToken.exp) {
        const {
          nbf: tokenNotValidBefore,
          exp: tokenNotValidAfter,
        } = decodedToken;
        const currentTimestamp = Math.round(new Date().valueOf() / 1000);
        return (
          tokenNotValidBefore < currentTimestamp &&
          currentTimestamp < tokenNotValidAfter
        );
      }
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getIdToken = () => {
  return sessionStorage.getItem('msal.idtoken');
};

export const getAccessToken = () => {
  return sessionStorage.getItem('msal.accesstoken');
};

export const isCordovaLoggedIn = () => {
  if (IS_CORDOVA()) {
    const idToken = getIdToken();
    return insecurlyVerifyJwtToken(idToken);
  }
  return false;
};

export const hasActiveBrowserSession = async () => {
  if (!IS_CORDOVA()) {
    if (isLocal) return true;
    const response = await fetch('/oauth2/auth');
    return response.status === 202;
  }
};

let cordovaLoginFailCount = 0;
export const debouncedLoginWithCordova = debounce(
  // eslint-disable-next-line no-use-before-define
  () => loginWithCordova(),
  500
);

const resetLoginCordovaTokens = () => {
  cordovaLoginFailCount = 0;
  window.cordova.plugins.msalPlugin.signOut();
  sessionStorage.setItem('msal.idtoken', null);
};

export const logoutCordovaAndLoginAgain = () => {
  resetLoginCordovaTokens();
  debouncedLoginWithCordova();
};

export const logout = () => {
  if (IS_CORDOVA()) {
    resetLoginCordovaTokens();
    if (!isOnCordovaLogoutPage())
      window.location.href = `/${routes.logoutCordova}`;
  } else {
    window.location.href = endpoints.azureLogout;
  }
};

export const handleLogin = responseString => {
  try {
    const response = JSON.parse(responseString);
    console.error('msal.idtoken', response.idToken);
    sessionStorage.setItem('msal.idtoken', response.idToken);
    sessionStorage.setItem('msal.accesstoken', response.accessToken);
  } catch (e) {
    sessionStorage.setItem('msal.idtoken', responseString);
  }
  window.location.reload();
};

export const handleFailedLogin = error => {
  cordovaLoginFailCount += 1;
  if (cordovaLoginFailCount <= 5) {
    debouncedLoginWithCordova();
  } else {
    window.alert(
      `We tried ${cordovaLoginFailCount} times to log you in, restart the app. If the problem persists, contact helpdesk`
    );
    logSentryError(
      `A user was unable to login after ${cordovaLoginFailCount} attempts`
    );
    logout();
  }
  console.error('cordova login error', error);
};

const authoritySetup = {
  authorities: [
    {
      type: 'AAD',
      audience: 'AzureADMyOrg',
      authorityUrl: '',
      cloudInstance: 'MSALAzurePublicCloudInstance',
      default: true,
    },
  ],
  authorizationUserAgent: 'DEFAULT',
  multipleCloudsSupported: false,
  brokerRedirectUri: true,
  accountMode: 'SINGLE',
  scopes: ['User.Read'],
};

export const loginWithCordova = () => {
  const isAvailable = typeof window.cordova.plugins.msalPlugin !== 'undefined';
  if (isAvailable && !isOnCordovaLogoutPage()) {
    if (!isCordovaLoggedIn()) {
      window.cordova.plugins.msalPlugin.msalInit(
        () => {
          window.cordova.plugins.msalPlugin.signInInteractive(
            handleLogin,
            handleFailedLogin
          );
        },
        handleFailedLogin,
        authoritySetup
      );
    }
  }
};

export const loginWhenCordovaDeviceIsReady = () => {
  document.addEventListener('deviceready', debouncedLoginWithCordova, false);
};
