import 'config/i18n.config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { DinoDetective } from 'icons';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import {
  AccountOptions,
  ChangelogPage,
  ConfirmationAlert,
  DownloadFilePage,
  EmptyState,
  FaqPage,
  HomePage,
  IdeaSurveyPage,
  NotificationReminderPage,
  PlaceholderPage,
  PreferencesPage,
  PreLoginPage,
  PullToRefresh,
  SatisfactionSurveyPage,
  ShowClick,
  SupportPage,
  SwitchEnvironmentPage,
  TaskListAndDetailWrapper,
  Toast,
} from 'components';
import { environment, routes } from 'config';
import store from 'store/store';
import { Wrapper } from './Wrapper';

// const EXCLUDED_PATHS_REGEX =  /^(?!\/oauth2|\/api|\/notifications).*$/
const persistor = persistStore(store);

const App = () => {
  const { t } = useTranslation();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <PreLoginPage>
            <Wrapper>
              <Switch>
                <Route path={routes.home} exact component={HomePage} />
                <Route
                  path={routes.changelog}
                  exact
                  component={ChangelogPage}
                />
                <Route
                  path={routes.preferences}
                  exact
                  component={PreferencesPage}
                />
                <Route path={routes.faq} exact component={FaqPage} />
                <Route
                  path={routes.switchEnvironment}
                  exact
                  component={SwitchEnvironmentPage}
                />
                <Route
                  path="/tasks/:section?/:taskId?/:subsection?/:subsectionId?"
                  component={TaskListAndDetailWrapper}
                />
                <Route
                  path={`${routes.placeholder}/:section?`}
                  exact
                  component={PlaceholderPage}
                />
                <Route
                  path={routes.downloadFile}
                  exact
                  component={DownloadFilePage}
                />
                <Route
                  path={routes.ideaSurvey}
                  exact
                  component={IdeaSurveyPage}
                />
                {console.log(routes.support)}
                <Route path={routes.support} exact component={SupportPage} />
                <Route
                  exact
                  path={/^(?!\/api).*$/}
                  render={() => (
                    <EmptyState
                      title={t('COMMON.NOT_FOUND_TITLE')}
                      subtitle={t('COMMON.NOT_FOUND_SUBTITLE')}
                      headerProps={{ to: '/', onlyMobile: false }}
                      Icon={DinoDetective}
                      data-cy="notFoundPage"
                      toHomePageButton
                    />
                  )}
                />
              </Switch>
              <AccountOptions />
              <PullToRefresh />
              {environment.interviewMode ? <ShowClick /> : null}
              <SatisfactionSurveyPage />
              <NotificationReminderPage />
              <Toast />
              <ConfirmationAlert />
            </Wrapper>
          </PreLoginPage>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
