import { IS_CORDOVA } from './responsive';

export const getPathFromLaunchUrl = launchURL =>
  launchURL.replace('approvals:/', '');

export const subscribeToNotifications = () => {
  if (
    IS_CORDOVA() &&
    window?.cordova?.plugins &&
    window.cordova.plugins.OpenAppSettings
  ) {
    window.cordova.plugins.OpenAppSettings.open();
  } else if (!IS_CORDOVA()) {
    window.OneSignal.push(() => {
      window.OneSignal.showSlidedownPrompt({ force: true });
    });
  }
};
