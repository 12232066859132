import { useEffect, useState } from 'react';
import { endpoints } from 'config';
import { IS_CORDOVA, logSentryError, secureFetch } from 'utils';

function downloadBlobToLocalSystem(blob, filename) {
  // https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const fakeLink = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    fakeLink.href = url;
    fakeLink.download = filename;
    fakeLink.click();
    window.URL.revokeObjectURL(url);
  }
}

export const useDownloadFile = ({ taskId, fileId, fileName, contentType }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const url = taskId
          ? `${endpoints.attachments.ariba}/${taskId}/${fileId}`
          : `${endpoints.attachments.travelAndExpenses}/${fileId}`;

        const urlQueryParamaters = `contentType=${encodeURIComponent(
          contentType
        )}&fileName=${encodeURIComponent(fileName)}`;
        const response = await secureFetch(
          `${url}?${urlQueryParamaters}`,
          {
            method: 'GET',
            responseType: contentType,
          },
          true
        );
        const returnedBlob = await response.blob();

        if (!returnedBlob.size) throw new Error('Empty blob size');

        const realBlob = new Blob([returnedBlob], { type: contentType });
        const fileURL = URL.createObjectURL(realBlob);
        if (IS_CORDOVA()) {
          // it's not possible to save files to iOS without extra permissions so that's why try to preview it
          if (window?.cordova?.InAppBrowser) {
            window.history.back();
            window.cordova.InAppBrowser.open(
              fileURL,
              '_blank',
              'toolbarposition=top'
            );
          } else {
            window.location.replace(fileURL);
          }
        } else {
          setLoading(false);
          downloadBlobToLocalSystem(returnedBlob, fileName);
        }
      } catch (e) {
        setError(true);
        logSentryError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    loading,
    error,
  };
};
