import { environment } from './environment.config';

const { oauthLocation, serverDomain, azureBaseUrl } = environment;
const serverOrigin = window.location.origin;
const oauth = `${serverOrigin}/oauth2`;
// TODO: scope thse on their resource purchaseRequest.pendingCount, travelAndExpense.pendingCoun, ...
export const endpoints = {
  tasks: `${serverDomain}/tasks/purchaseRequests`,
  purchaseRequests: `${serverDomain}/tasks/purchaseRequests`,
  searchTasks: `${serverDomain}/tasks/purchaseRequests/search`,
  pendingCount: `${serverDomain}/tasks/purchaseRequests/pendingCount`,
  tasksLineItems: `${serverDomain}/tasks/purchaseRequests/lineItems`,
  travelAndExpenses: `${serverDomain}/tasks/travelAndExpenses`,
  pendingTravelAndExpensesCount: `${serverDomain}/tasks/travelAndExpenses/pendingCount?statusCode=W`,
  pushNotificationSetIdentity: `${serverDomain}/pushNotification/setIdentity`,
  respond: {
    travelAndExpenses: `${serverDomain}/tasks/travelAndExpenses/respond`,
    regularPurchaseRequests: `${serverDomain}/tasks/purchaseRequests/respond`,
    aribaPurchaseRequest: `${serverDomain}/tasks/purchaseRequests/ariba/respond`,
  },
  contact: `${serverDomain}/microsoft/graph`,
  oauth,
  oauthRedirect: `${oauth}/sign_in?rd=${window?.location?.pathname}`,
  logout: `${oauth}/sign_out?rd=${oauthLocation}/auth/realms/approvals/protocol/openid-connect/logout?redirect_uri=${serverOrigin}/oauth2/start`,
  azureLogout: `${azureBaseUrl}/logout?post_logout_redirect_uri=${serverOrigin}/oauth2/sign_out`,
  userInfo: `${serverDomain}/user`,
  preferences: `${serverDomain}/preferences`,
  environments: `${serverDomain}/environments`,
  attachments: {
    travelAndExpenses: `${serverDomain}/tasks/travelAndExpenses/attachments`,
    ariba: `${serverDomain}/tasks/purchaseRequests/ariba/attachments`,
  },
  ping: `${serverDomain}`,
  incidents: `${serverDomain}/incidents`,
};
