import 'default-passive-events';
import './polyfills';
import './utils/universalLinking';
import React from 'react';
import ReactDOM from 'react-dom';
import { Banner } from 'components';
import App from 'components/App';
import { initSentry } from 'config/sentry.config';
import { en } from 'config/translations';
import {
  approvalsConfig,
  IS_INTERNET_EXPLORER,
  loginWhenCordovaDeviceIsReady,
} from 'utils';

loginWhenCordovaDeviceIsReady();

const renderApp = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

const renderIEDeprecationWarning = () => {
  if (IS_INTERNET_EXPLORER) {
    ReactDOM.render(
      <Banner text={en.translation.COMMON.IE_DEPRECATION_WARNING} closable />,
      document.getElementById('banner')
    );
  }
};

initSentry();

renderApp();
renderIEDeprecationWarning();
// If service workers are supported, and one isn't already registered
if (
  'serviceWorker' in navigator &&
  !navigator.serviceWorker.controller &&
  approvalsConfig.oneSignalEnabled
) {
  navigator.serviceWorker.register('/OneSignalSDKWorker.js');
}

function onResume() {
  if (window.cordova.plugins.notification) {
    // we can't use hooks here :/
    const totalPendingCount = localStorage.getItem('totalPendingCount');
    setTimeout(() => {
      // doesn't work without setTimeout
      // window.cordova.plugins.notification.badge.configure({ autoClear: false }); doesn't work https://github.com/katzer/cordova-plugin-badge/issues/31
      window.cordova.plugins.notification.badge.set(totalPendingCount);
    }, 1000);
  }
}

document.addEventListener(
  'deviceready',
  () => {
    console.error('deviceready');
    document.addEventListener('resume', onResume, false);
  },
  false
);

// serviceWorker.unregister();
// const iOS = !!navigator.platform && /i(Phone|Pad|Pod)/.test(navigator.platform);
// if (!iOS) {
//   serviceWorker.register({
//     onUpdate: registration => {
//       const waitingServiceWorker = registration.waiting;

//       if (waitingServiceWorker) {
//         waitingServiceWorker.addEventListener('statechange', event => {
//           if (event.target.state === 'activated') {
//             console.info('reloading');
//             // window.location.reload();
//           }
//         });

//         // This basically forces the new service worker(since the update) to become the active service worker
//         waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//       }
//     },
//   });
// }
