import theme from 'theme/theme';

const { colors } = theme;

export const LINE_HEIGHT = 1.1;
export const STATUS_COLORS = {
  PENDING: colors.primary,
  APPROVED: colors.green,
  REJECTED: colors.red[5],
  DELETED: colors.secondary,
};
