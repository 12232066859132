import { endpoints } from 'config';
import {
  convertToSapStatus,
  executeRegularOrAribaPurchaseRequestLogic,
  getPurchaseRequestIdAndItemId,
  logSentryError,
  logSentryMessage,
  mataHari,
  secureFetch,
  sleep,
} from 'utils';
import { loadingState, overridesUpdate } from '../tasks.duck';
import {
  initialStateOfStatus,
  purchaseRequestsUpdate,
} from './purchaseRequests.duck';
// eslint-disable-next-line import/no-self-import
import * as thisModule from './purchaseRequests.service'; // necesarry to make unit tests work https://stackoverflow.com/questions/45111198/how-to-mock-functions-in-the-same-module-using-jest

const PAGE_SIZE = 30;
const DEFAULT_SORTING_FIELD = 'requestDate';
const DEFAULT_SORTING_DIRECTION = 'DESC';
const allDataFetched = data => data.length < 30;

export const fetchPurchaseRequests = async (
  dispatch,
  statusToFetch = 'PENDING',
  sortingField = DEFAULT_SORTING_FIELD,
  sortingDirection = DEFAULT_SORTING_DIRECTION
) => {
  try {
    const url = `${endpoints.purchaseRequests}?statusCode=${statusToFetch}&sortField=${sortingField}&sortDirection=${sortingDirection}&pageNumber=1&pageSize=${PAGE_SIZE}`;
    const data = await secureFetch(url);

    dispatch(
      purchaseRequestsUpdate({
        [statusToFetch]: {
          loading: false,
          pageNumber: 1,
          data,
          allDataFetched: allDataFetched(data),
        },
      })
    );
  } catch (error) {
    dispatch(purchaseRequestsUpdate({ [statusToFetch]: { error } }));
  }
};

export const fetchMorePurchaseRequests = async ({
  dispatch,
  category,
  pageNumber,
  sortingField,
  sortingDirection,
  currentTasks,
  dynamicParams,
}) => {
  try {
    const url = `${endpoints.purchaseRequests}?${dynamicParams}&sortField=${sortingField}&sortDirection=${sortingDirection}&pageNumber=${pageNumber}&pageSize=${PAGE_SIZE}`;
    const newTasks = await secureFetch(url);
    dispatch(
      purchaseRequestsUpdate({
        [category]: {
          pageNumber,
          data: [...currentTasks.data, ...newTasks],
          loadingMoreTasks: false,
          allDataFetched: allDataFetched(newTasks),
        },
      })
    );
  } catch (error) {
    dispatch(
      purchaseRequestsUpdate({
        [category]: { ...currentTasks, errorOnFetchingMore: error },
      })
    );
  }
};

export const searchPurchaseRequests = async ({
  dispatch,
  searchQuery,
  sortingField,
  sortingDirection,
  enableLoadingState,
}) => {
  if (!dispatch && !searchQuery) {
    return;
  }
  if (enableLoadingState) {
    dispatch(
      purchaseRequestsUpdate({
        SEARCH: loadingState,
      })
    );
  }
  try {
    const data = await secureFetch(
      `${endpoints.searchTasks}?searchTerm=${searchQuery}&sortField=${sortingField}&sortDirection=${sortingDirection}&pageNumber=1&pageSize=${PAGE_SIZE}`
    );
    dispatch(
      purchaseRequestsUpdate({
        SEARCH: {
          loading: false,
          pageNumber: 1,
          data,
          allDataFetched: allDataFetched(data),
        },
      })
    );
  } catch (error) {
    dispatch(purchaseRequestsUpdate({ SEARCH: { error } }));
  }
};

export const fetchPendingPurchaseRequestsCount = async dispatch => {
  try {
    const json = await secureFetch(endpoints.pendingCount);
    const { pending_purchase_requests: pendingCountString } = json;
    const pendingCount = Number(pendingCountString);

    dispatch(
      purchaseRequestsUpdate({
        pendingCount,
        loadingPendingCount: false,
      })
    );
  } catch (error) {
    // error is already caught in secureFetch but we do not want to send it twice
  }
};

export const fetchPurchaseRequestLineItems = async (dispatch, sharedId) => {
  const defaultState = { sharedId, selected: {} };
  dispatch(
    purchaseRequestsUpdate({
      LINE_ITEMS: { ...loadingState, ...defaultState },
    })
  );
  try {
    const data = await secureFetch(`${endpoints.tasksLineItems}/${sharedId}`);
    // const { pending_purchase_requests: pendingCountString } = json;
    // const pendingCount = Number(pendingCountString);

    dispatch(
      purchaseRequestsUpdate({
        LINE_ITEMS: {
          data,
          ...defaultState,
        },
      })
    );
  } catch (error) {
    dispatch(
      purchaseRequestsUpdate({
        LINE_ITEMS: { ...initialStateOfStatus, error, ...defaultState },
      })
    );
  }
};

export const getRegularPurchaseRequestsRespondBody = (
  tasks,
  newStatus,
  commentNotes,
  dispatch
) => {
  const action = convertToSapStatus(newStatus);
  let itemsInBody = [];

  tasks.forEach(({ id, approvalSequence }, index) => {
    dispatch(
      overridesUpdate({
        id,
        values: { updating: true, message: null, newStatus, notify: true },
      })
    );

    const {
      purchaseRequestId: requestID,
      itemId: requestItem,
    } = getPurchaseRequestIdAndItemId(id);

    const pendingSequences = approvalSequence.filter(sequence => {
      return sequence.status === 'PENDING';
    });
    let releaseCode;
    if (pendingSequences[0]) {
      const { sequence } = pendingSequences[0];
      releaseCode = sequence;
    } else {
      logSentryMessage(`Unable to indentify approver for id ${id}`, {
        id,
        approvalSequence,
      });
      dispatch(
        overridesUpdate({
          id,
          values: {
            updating: false,
            message: 'Unable to indentify approver',
            internalError: true,
          },
        })
      );
    }
    if (releaseCode) {
      itemsInBody = [
        ...itemsInBody,
        {
          requestID,
          requestItem,
          action,
          releaseCode,
          rejectNote: commentNotes[index],
        },
      ];
    }
  });
  return { items: itemsInBody };
};

export const refetchDataAfterUpdate = ({
  sharedId,
  sortingField,
  sortingDirection,
  newStatus,
  searchQuery,
  dispatch,
}) => {
  if (sharedId) {
    thisModule.fetchPurchaseRequestLineItems(dispatch, sharedId);
  }
  thisModule.fetchPurchaseRequests(
    dispatch,
    'PENDING',
    sortingField,
    sortingDirection
  );
  if (newStatus === 'APPROVED')
    thisModule.fetchPurchaseRequests(
      dispatch,
      'APPROVED',
      sortingField,
      sortingDirection
    );
  if (newStatus === 'REJECTED')
    thisModule.fetchPurchaseRequests(
      dispatch,
      'REJECTED',
      sortingField,
      sortingDirection
    );
  if (searchQuery)
    thisModule.searchPurchaseRequests({
      dispatch,
      searchQuery,
      sortingField,
      sortingDirection,
      enableLoadingState: false,
    });
};

export const parseRegularPurchaseRequestResponse = responseText =>
  JSON.parse(
    responseText
      .replace(/\s/g, '')
      .replace(/}}}$/g, ']}}')
      .replace(/MSG":{/g, 'MSG":[')
      .replace(/"item":/g, '')
  );

export const getAribaPurchaseRequestRespondBody = (
  { id },
  newStatus,
  commentNote,
  dispatch
) => {
  dispatch(
    overridesUpdate({
      id,
      values: { updating: true, message: null, newStatus, notify: true },
    })
  );
  return {
    approvableId: id,
    comment: commentNote,
    visibleToSupplier: 'true',
    action: convertToSapStatus(newStatus),
  };
};

export const dealWithRegularPurchaseRequestResponse = async ({
  shouldSleep = true,
  responseJson,
  newStatus,
  dispatch,
  ...extraRefetchParameters
}) => {
  // This avoids race conditions and give MuleSoft time to update it's database
  if (shouldSleep) await sleep(5000);
  let atlLeastOneSuccesfull = false;
  responseJson['Z_MM_PREQ_APPROVE_REJECT.Response'].EM_PREQ_MSG.forEach(
    ({
      MESSAGE: message,
      SUCCESS: success,
      BANFN: requestId,
      BNFPO: itemId,
    }) => {
      const id = `${requestId}::${itemId.replace(/^0+/g, '')}`;

      if (success) {
        if (newStatus === 'APPROVED') mataHari.purchaseRequestApproved();
        if (newStatus === 'REJECTED') mataHari.purchaseRequestRejected();
      } else {
        mataHari.purchaseRequestApprovalFailed();
      }

      if (!message && !success) {
        message = 'Unknown error';
      }
      if (!success) {
        logSentryError(`${id} ${message}`, {
          causedBy: 'SAP',
        });
      }
      if (success) atlLeastOneSuccesfull = true;
      dispatch(
        overridesUpdate({
          id,
          values: {
            updating: false,
            message,
            success,
            notify: true,
          },
        })
      );
    }
  );

  if (atlLeastOneSuccesfull) {
    thisModule.refetchDataAfterUpdate({
      newStatus,
      dispatch,
      ...extraRefetchParameters,
    });
  }
};

export const dealWithAribaPurchaseRequestResponse = ({
  responseJson,
  dispatch,
  ...extraRefetchParameters
}) => {
  if (responseJson.error !== 'OK') {
    dispatch(
      overridesUpdate({
        id: responseJson.id,
        values: {
          updating: false,
          message: responseJson.description,
          success: '',
          notify: true,
        },
      })
    );
    logSentryError(`${responseJson.id} ${responseJson.description}`, {
      causedBy: 'MuleSoft or Ariba',
    });
  } else {
    dispatch(
      overridesUpdate({
        id: responseJson.id,
        values: {
          updating: false,
          message: '',
          success: 'x',
          notify: true,
        },
      })
    );
    thisModule.refetchDataAfterUpdate({
      dispatch,
      ...extraRefetchParameters,
    });
  }
};

export const updatePurchaseRequestStatus = async (
  dispatch,
  newStatus,
  tasks,
  commentNotes,
  sortingField,
  sortingDirection,
  searchQuery,
  sharedId,
  shouldSleep = true
) => {
  let dependingOnPurchaseRequestType;
  executeRegularOrAribaPurchaseRequestLogic({
    task: tasks[0],
    aribaCallback: () => {
      dependingOnPurchaseRequestType = {
        respondEndpoint: endpoints.respond.aribaPurchaseRequest,
        bodyParameters: thisModule.getAribaPurchaseRequestRespondBody(
          tasks[0],
          newStatus,
          commentNotes[0],
          dispatch
        ),
        dealWithResponse: async ({ response, ...restOfParameters }) => {
          const responseJson = await response.json();
          thisModule.dealWithAribaPurchaseRequestResponse({
            responseJson: {
              id: tasks[0].id,
              ...responseJson,
            },
            ...restOfParameters,
          });
        },
      };
    },
    regularPurhcaseRequestCallback: () => {
      dependingOnPurchaseRequestType = {
        respondEndpoint: endpoints.respond.regularPurchaseRequests,
        bodyParameters: thisModule.getRegularPurchaseRequestsRespondBody(
          tasks,
          newStatus,
          commentNotes,
          dispatch
        ),
        dealWithResponse: async ({ response, ...restOfParameters }) => {
          const responseText = await response.text();
          thisModule.dealWithRegularPurchaseRequestResponse({
            responseJson: parseRegularPurchaseRequestResponse(responseText),
            shouldSleep,
            ...restOfParameters,
          });
        },
      };
    },
  });

  try {
    const response = await secureFetch(
      dependingOnPurchaseRequestType.respondEndpoint,
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        mode: 'cors',
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(dependingOnPurchaseRequestType.bodyParameters),
      },
      true
    );
    await dependingOnPurchaseRequestType.dealWithResponse({
      response,
      sharedId,
      sortingField,
      sortingDirection,
      newStatus,
      searchQuery,
      dispatch,
    });
  } catch (error) {
    mataHari.purchaseRequestApprovalFailed();
    logSentryError(error);
    console.error(error);
    tasks.forEach(({ id }) => {
      dispatch(
        overridesUpdate({
          id,
          values: {
            updating: false,
            message: 'Unknown error',
            internalError: true,
            notify: true,
          },
        })
      );
    });
  }
};
