import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SinglePageTemplate } from 'components';
import { StyledSeperator } from 'styled';

const StyledParagraph = styled.p`
  font-size: inherit;
  max-width: 500px;
`;
const StyledTitle = styled.h3`
  font-size: inherit;
`;

const StyledAnchor = styled.a`
  font-size: inherit;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  display: block;
  margin-top: ${({ theme }) => theme.space[3]}px;
`;

const StyledSection = styled.section`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  padding: ${({ theme }) => theme.space[4]}px ${({ theme }) => theme.space[6]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    padding-left: 0px;
  }
`;

const ExtraStyledSeperator = styled(StyledSeperator)`
  margin: 0px ${({ theme }) => theme.space[2]}px;
  width: calc(100% - (${({ theme }) => theme.space[2]}px * 2));
  max-width: calc(500px + (${({ theme }) => theme.space[4]}px * 2));
  background-color: ${({ theme }) => theme.colors.lightGray};
  @media only screen and (min-width: ${({ theme }) => theme.breakPoints[3]}) {
    margin: 0;
    max-width: calc(500px + ${({ theme }) => theme.space[4]}px);
  }
`;

export const SupportPage = () => {
  const { t } = useTranslation();

  return (
    <SinglePageTemplate title={t('COMMON.SUPPORT')}>
      <StyledSection>
        <StyledParagraph>
          {t('SUPPORT.INTRO_TITLE')}
          <br />
          <br />
          {t('SUPPORT.INTRO_DESCRIPTION')}
        </StyledParagraph>
      </StyledSection>
      <ExtraStyledSeperator />
      <StyledSection>
        <StyledTitle>{t('SUPPORT.ISSUE_TITLE')}</StyledTitle>
        <StyledParagraph>{t('SUPPORT.ISSUE_DESCRIPTION')}</StyledParagraph>
        <StyledAnchor
          target="_blank"
          href="https://excalibur.service-now.com/csm?id=sc_cat_item&sys_id=2308b04e4fc36b44123eecd18110c724"
        >
          {t('SUPPORT.ISSUE_ACTION')}
        </StyledAnchor>
      </StyledSection>
      <ExtraStyledSeperator />
      <StyledSection>
        <StyledTitle>{t('SUPPORT.SUGGESTION_TITLE')}</StyledTitle>
        <StyledParagraph>{t('SUPPORT.SUGGESTION_DESCRIPTION')}</StyledParagraph>
        <StyledAnchor
          target="_blank"
          href="https://clicktime.symantec.com/3DyJS7H2yWs7ZcHPBz4oi4k6H2?u=https%3A%2F%2Fexcalibur.service-now.com%2Fcsm%3Fid%3Dsc_cat_item%26sys_id%3D5e7d18b01ba760909d2e4150f54bcb05%26sysparm_category%3D4bc62cfbdb7c8418368ed18c68961931%26catalog_id%3De0d08b13c3330100c8b837659bba8fb4"
        >
          {t('SUPPORT.SUGGESTION_ACTION')}
        </StyledAnchor>
      </StyledSection>
      <ExtraStyledSeperator />
    </SinglePageTemplate>
  );
};
