/* eslint-disable no-alert */
import { userInterfaceUpdate } from 'store/userInterface/userInterface.duck';
import { IS_MICROSOFT_APP } from './responsive';

export const confirm = ({
  message,
  succesCallback,
  cancelCallback = () => {},
  confirmText = 'Ok',
  cancelText = 'Cancel',
  dispatch,
}) => {
  if (IS_MICROSOFT_APP) {
    dispatch(
      userInterfaceUpdate({
        confirmAlert: {
          active: true,
          text: message,
          succesCallback,
          confirmText,
          cancelText,
        },
      })
    );
  } else if (window.confirm(message)) {
    succesCallback();
  } else {
    cancelCallback();
  }
};
