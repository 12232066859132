import { endpoints } from "config";
import { logSentryError, secureFetch } from "./secureFetch";

export const getIncidents = async () => {
  let json = null;
  try {
    json = await secureFetch(endpoints.incidents);
  } catch (e) {
    // something went wrong while retrieving incidents
    logSentryError(e);
  }

  return json;
}
