import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIncidents } from 'utils/incidents';

export const useIncidentsBanner = () => {
  const { t } = useTranslation();
  const [bannerTitle] = useState(t('INCIDENT_BANNER.TITLE'));
  const [incidents, setIncidents] = useState({
    bannerEnabled: false,
    bannerText: '',
    bannerTitle,
  });
  useEffect(() => {
    getIncidents().then(allIncidents => {
      if (allIncidents) {
        const incidentsForBanner = allIncidents.filter(
          incident => incident.bannerEnabled && incident.active
        );
        let bannerText = '';
        for (const incident of incidentsForBanner) {
          bannerText += incident.bannerText ?? incident.summary;
          bannerText += '\n';
        }
        const bannerEnabled = incidentsForBanner.length > 0;
        setIncidents({ bannerText, bannerEnabled, bannerTitle });
      }
    });
  }, []);
  return incidents;
};
