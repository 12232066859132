import { borealisTheme } from '@borealisgroup/theme';

export default {
  ...borealisTheme,
  colors: {
    ...borealisTheme.colors,
    primary: borealisTheme.colors.secondary,
    secondary: '#767676',
    background: '#F9F9F9',
    darkFocus: '#D9EAF6',
    focus: '#F5F9FD',
    focusDark: borealisTheme.colors.darkblue[6],
    borderFocus: borealisTheme.colors.lightblue[2],
    lightGray: '#EEEEEE',
    green: '#539f00',
    lightGreen: '#f0f6e9',
    grayText: '#939393',
    gray: '#c3c3c3',
    darkerGray: '#7f7f7f',
    black: '#1d1e1f',
    lighterBlack: '#3b3b3b',
    darkerWhite: '#CCC',
  },
  breakPoints: borealisTheme.breakPoints.map(
    breakPoint => `${Number(breakPoint.replace(/\D/g, '')) + 1}px`
  ),
  space: [
    ...borealisTheme.space, // Ends at 128
    256,
  ],
  // customize the default theme here
};
