import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endpoints, oneSignalAppIds } from 'config';
import { userUpdate } from 'store/user/user.duck';
import { getCurrentUser, getOneSignalId } from 'store/user/user.selectors';
import {
  approvalsConfig,
  frontendEnvironment,
  getPathFromLaunchUrl,
  IS_CORDOVA,
  secureFetch,
} from 'utils';

export const oneSignalSetIdDelay = 1000;
const oneSignalAppId = oneSignalAppIds[frontendEnvironment];

const allowedNotificationsInSettings = 2;
const useCordovaOneSignalId = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const cordovaCheck = (timeout = 500) => {
      setTimeout(() => {
        // timeout necessary since iOS settings don't update instantly
        window.plugins.OneSignal.setSubscription(true);
        window.plugins.OneSignal.getPermissionSubscriptionState(status => {
          const { userId: oneSignalId } = status.subscriptionStatus;
          if (
            oneSignalId &&
            status.permissionStatus.status === allowedNotificationsInSettings
          ) {
            dispatch(userUpdate({ oneSignalId }));
          } else {
            dispatch(userUpdate({ oneSignalId: null }));
          }
        });
      }, timeout);
    };
    const cordovaSetup = () => {
      if (oneSignalAppId) {
        window.plugins.OneSignal.startInit(oneSignalAppId)
          .iOSSettings({
            kOSSettingsKeyAutoPrompt: true,
            kOSSettingsKeyInAppLaunchURL: false,
          })
          .inFocusDisplaying(
            window.plugins.OneSignal.OSInFocusDisplayOption.Notification
          )
          .handleNotificationOpened(openResult => {
            window.location.pathname = getPathFromLaunchUrl(
              openResult.notification.payload.launchURL
            );
          })
          .endInit();
        cordovaCheck(2000);
      }
    };
    document.addEventListener(
      'deviceready',
      () => {
        cordovaSetup();
        document.addEventListener('resume', cordovaCheck, false);
      },
      false
    );
  }, [dispatch]);
};

const useBrowserOneSignalId = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      const OneSignal = window.OneSignal || [];
      if (
        approvalsConfig.oneSignalEnabled &&
        OneSignal &&
        OneSignal.init &&
        oneSignalAppId
      ) {
        OneSignal.init({
          appId: oneSignalAppId,
        });
        OneSignal.push(() => {
          OneSignal.on('subscriptionChange', isSubscribed => {
            if (isSubscribed) {
              OneSignal.getUserId(oneSignalId => {
                dispatch(userUpdate({ oneSignalId }));
              });
            }
          });
          OneSignal.isPushNotificationsEnabled(isEnabled => {
            if (isEnabled) {
              OneSignal.getUserId(oneSignalId => {
                dispatch(userUpdate({ oneSignalId }));
              });
            }
          });
        });
      }
    }, oneSignalSetIdDelay);
  }, [dispatch]);
};

const useSendOneSignalIdToServer = () => {
  const currentUser = useSelector(getCurrentUser);
  const savedOneSignalId = useSelector(getOneSignalId);
  useEffect(() => {
    if (savedOneSignalId && !currentUser.initial) {
      const body = {
        oneSignalId: savedOneSignalId,
        oneSignalAppId,
        user: currentUser,
        environment: frontendEnvironment,
      };
      secureFetch(endpoints.pushNotificationSetIdentity, {
        method: 'POST',
        body: JSON.stringify(body),
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  }, [currentUser, savedOneSignalId]);
};

export const useOneSignalId = () => {
  useBrowserOneSignalId();
  useCordovaOneSignalId();
  useSendOneSignalIdToServer();
};

export const useSubscribedToPushNotifications = () => {
  const oneSignalId = useSelector(getOneSignalId);
  if (
    IS_CORDOVA() &&
    window?.cordova?.plugins &&
    !window.cordova.plugins.OpenAppSettings
  ) {
    return true;
  }
  return Boolean(oneSignalId);
};
