import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/globalStyle';
import theme from 'theme/theme';
import { LoadingTemplate, NoConnectionPage } from 'components';

const StyledParagraphContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledParagraph = styled.p`
  font-size: ${theme.fontSizes[2]}px;
  max-width: calc(100% - ${theme.space[9]}px);
  text-align: center;
`;

export const PreLoginPage = ({
  t,
  isBlockedWhileLoggingIn,
  isOnCordovaLogoutPageValue,
}) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      {isOnCordovaLogoutPageValue ? (
        <StyledParagraphContainer>
          <StyledParagraph>{t('PRE_LOGIN_PAGE.LOGGED_OUT')}</StyledParagraph>
        </StyledParagraphContainer>
      ) : window.navigator.onLine ? (
        <LoadingTemplate
          t={t}
          isBlocked={isBlockedWhileLoggingIn}
          title={t('PRE_LOGIN_PAGE.HOLD_ON')}
        />
      ) : (
        <NoConnectionPage />
      )}
    </>
  </ThemeProvider>
);
