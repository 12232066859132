import { borealisTheme } from '@borealisgroup/theme';

export const isMobile = () => {
  const breakPointsNumber = Number(
    borealisTheme.breakPoints[3].replace(/\D/g, '')
  );
  return breakPointsNumber > window.innerWidth;
};

export const IS_INTERNET_EXPLORER = (() => {
  const ua = window.navigator.userAgent;
  return ua.includes('MSIE') || ua.includes('Trident');
})();

export const IS_MICROSOFT_EDGE = (() => {
  const ua = window.navigator.userAgent;
  const edge = ua.indexOf('Edge');

  return edge >= 0;
})();

export const IS_MICROSOFT_APP = window.navigator.userAgent.includes(
  'MSAppHost/3.0'
);

export const IOS_VERSION = (() => {
  const agent = window.navigator.userAgent;
  const start = agent.indexOf('OS ');
  if (
    (agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) &&
    start > -1
  ) {
    const iOSVersionNumber = window.Number(
      agent.substr(start + 3, 4).replace('_', '.')
    );
    return iOSVersionNumber;
  }
  return null;
})();

export const supportsSVGAnimations = () => {
  return !(IS_INTERNET_EXPLORER || IS_MICROSOFT_EDGE);
};

export const IS_CORDOVA = () => {
  const { userAgent } = window.navigator;
  return (
    (userAgent.includes('iPhone') || userAgent.includes('iPad')) &&
    !userAgent.includes('Safari')
  );
};

function compareVersions(a, b) {
  // https://stackoverflow.com/questions/6832596/how-to-compare-software-version-number-using-js-only-number
  let i;
  let diff;
  const regExStrip0 = /(\.0+)+$/;
  const segmentsA = a.replace(regExStrip0, '').split('.');
  const segmentsB = b.replace(regExStrip0, '').split('.');
  const l = Math.min(segmentsA.length, segmentsB.length);

  for (i = 0; i < l; i++) {
    diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
    if (diff) {
      return diff;
    }
  }
  return segmentsA.length - segmentsB.length;
}

export const cordovaBuildVersionIsAtLeast = expectedVersionNumber => {
  if (!window?.BuildInfo?.version) return false;
  if (!/\d+\.\d+\.\d+/.test(expectedVersionNumber)) return false;
  return compareVersions(window.BuildInfo.version, expectedVersionNumber) >= 0;
};

export const canShowSurveyOnIOS = () => {
  if (IS_CORDOVA()) {
    return cordovaBuildVersionIsAtLeast('1.0.96');
  }
  return true;
};
