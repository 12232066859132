const taskList = '/tasks/:section';
const taskDetail = `${taskList}/:taskId`;
const taskDetailConfirmReject = `${taskDetail}/confirm-reject`;
const taskDetailContact = `${taskDetail}/contact/:contactId`;

export const routes = {
  changelog: '/changelog',
  confirmReject: 'confirm-reject', // to be used in combination with multiple
  confirmApprove: 'confirm-approve',
  contact: 'contact',
  downloadFile: `/download-file/:fileId/:taskId?`,
  faq: `/faq`,
  home: '/',
  logoutCordova: 'logout',
  ideaSurvey: '/idea-survey',
  multiple: 'multiple',
  placeholder: `/placeholder`,
  preferences: `/preferences`,
  support: '/support',
  switchEnvironment: `/switchEnvironment`,
  taskDetail,
  taskDetailConfirmReject,
  taskDetailContact,
  taskList,
};
