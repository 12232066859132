import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'theme/globalStyle';
import theme from 'theme/theme';
import { MaintenanceBanner, NoConnectionPage } from 'components';
import {
  useAppIconBadge,
  useChangeTitleOnNewPendingTasks,
  useFetchUserPreferences,
  useOneSignalId,
  useRefreshAllTasks,
  useSetSentryScope,
  useShowNotificationReminder,
  useShowSurvey,
  useTrackingLoginActivity,
} from 'hooks';
import { getPurchaseRequestTasks } from 'store/tasks/tasks.selectors';

export const Wrapper = ({ children }) => {
  useSetSentryScope();
  useOneSignalId();
  useRefreshAllTasks();
  useAppIconBadge();
  useShowSurvey();
  useShowNotificationReminder();
  useTrackingLoginActivity();
  useFetchUserPreferences();
  useChangeTitleOnNewPendingTasks();

  const tasks = useSelector(getPurchaseRequestTasks);
  if (tasks.initialState) return null;
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <MaintenanceBanner />
        {window.navigator.onLine ? <>{children}</> : <NoConnectionPage />}
      </>
    </ThemeProvider>
  );
};
