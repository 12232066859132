import React from 'react';
import { LinkOrAnchor, ToastDownloadFile } from 'components';
import { routes } from 'config';
import { injectRouteParameter, IS_CORDOVA, IS_MICROSOFT_APP } from 'utils';

export const FileLink = ({
  contentType = 'application/pdf',
  taskId,
  fileId,
  fileName = 'approvals-file',
  children,
  onClick,
  showDownloadToast,
  className,
}) => {
  const openInEdgeIfMicrosoftApp = IS_MICROSOFT_APP
    ? `microsoft-edge:${window.location.origin}`
    : '';

  const handleFileDownloadOnSeperatePageOrOnClick = () => {
    if (IS_CORDOVA() || openInEdgeIfMicrosoftApp) {
      return {
        to: `${openInEdgeIfMicrosoftApp}${injectRouteParameter(
          routes.downloadFile,
          {
            fileId,
            taskId,
          }
        )}?contentType=${contentType}&fileName=${fileName}`,
      };
    }
    return {
      onClick,
    };
  };
  return (
    <LinkOrAnchor
      {...handleFileDownloadOnSeperatePageOrOnClick()}
      data-cy="downloadFileLink"
      className={className}
    >
      {showDownloadToast ? (
        <ToastDownloadFile
          fileId={fileId}
          taskId={taskId}
          contentType={contentType}
          fileName={fileName}
          key={
            showDownloadToast /* thanks to the key here you can download the same file multiple times */
          }
        />
      ) : null}
      {children}
    </LinkOrAnchor>
  );
};
