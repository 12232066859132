import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { endpoints } from 'config';
import {
  hasActiveBrowserSession,
  IS_CORDOVA,
  isCordovaLoggedIn,
  isOnCordovaLogoutPage,
  logSentryError,
  redirectWebflow,
} from 'utils';
import { PreLoginPage } from './PreLoginPage';

export const PreLoginPageContainer = ({ children, ...props }) => {
  const { t } = useTranslation();
  const [isBlockedWhileLoggingIn, setIsBlockedWhileLoggingIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authCheckResolved, setAuthCheckResolved] = useState(false);
  useEffect(() => {
    if (!IS_CORDOVA() && !isLoggedIn) {
      if (
        !window.location.href.includes(endpoints.oauth) &&
        authCheckResolved
      ) {
        // eslint-disable-next-line no-alert
        redirectWebflow();
      }
    }
  }, [authCheckResolved, isLoggedIn]);

  useEffect(() => {
    if (!IS_CORDOVA()) {
      hasActiveBrowserSession().then(result => {
        setIsLoggedIn(result);
        setAuthCheckResolved(true);
      });
    } else {
      setIsLoggedIn(isCordovaLoggedIn());
    }
  }, [isLoggedIn, authCheckResolved]);
  const isOnCordovaLogoutPageValue = isOnCordovaLogoutPage();
  useEffect(() => {
    const time = 8000;
    const reportNotLoggedIn = setTimeout(() => {
      logSentryError(
        `A user was waiting to be logged in for more than ${time} milliseconds`
      );
      setIsBlockedWhileLoggingIn(true);
    }, time);
    if (isLoggedIn || isOnCordovaLogoutPageValue)
      clearTimeout(reportNotLoggedIn);
    return () => clearTimeout(reportNotLoggedIn);
  }, [isLoggedIn, isOnCordovaLogoutPageValue]);

  if (isLoggedIn) return children;
  return (
    <PreLoginPage
      isOnCordovaLogoutPageValue={isOnCordovaLogoutPageValue}
      isBlockedWhileLoggingIn={isBlockedWhileLoggingIn}
      t={t}
      {...props}
    />
  );
};
